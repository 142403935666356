import React from 'react';
import mp5 from '../../../images/article/mp5.webp';
import Articles from './Articles';
const MojPrad50 = () => {
    return (
        <Articles
        title="Najnowsza wersja programu mój prąd? - Mój prąd 5.0"
        img={mp5}
        tit1="Pomyśl o fotowoltaice już dziś!"
        par1="Mój Prąd to program, który przyczynił się do znacznego wzrostu popularności fotowoltaiki w Polsce. Program oferuje wsparcie finansowe dla osób fizycznych, które wytwarzają energię elektryczną na własne potrzeby. Pierwsze trzy edycje Mój Prąd koncentrowały się wyłącznie na elektrowniach słonecznych, a czwarta edycja wprowadziła do programu magazyny energii, ciepła oraz HEMS, czyli domowe systemy zarządzania energią. W planowanej piątej edycji Mój Prąd, Polacy mogą spodziewać się wsparcia również dla kolektorów słonecznych oraz pomp ciepła. "
        par2="Mój Prąd 5.0 jest adresowany do osób fizycznych, które wytwarzają energię elektryczną na własne potrzeby. W IV edycji programu wsparcie finansowe można było uzyskać tylko w przypadku, gdy prosument rozliczał się w net-billingu lub systemach alternatywnych. W piątej edycji programu zasady najprawdopodobniej będą kontynuowane. Program Mój Prąd 5.0 będzie skierowany do osób, które chcą zwiększyć swoją autokonsumpcję i magazynować energię pochodzącą z instalacji fotowoltaicznych oraz z innych źródeł energii."
        par3=" Mój Prąd 5.0 oferuje wsparcie finansowe dla osób, które chcą zwiększyć swoją autokonsumpcję i magazynować energię pochodzącą z instalacji fotowoltaicznych oraz z innych źródeł energii. W pierwszych trzech edycjach programu wsparcie obejmowało wyłącznie instalacje fotowoltaiczne, a czwarta edycja była krokiem w kierunku efektywnej i bezpiecznej energetyki. W IV edycji programu można było uzyskać środki finansowe na magazyny energii, ciepła oraz HEMS/EMS. W piątej edycji programu Mój Prąd 5.0, oprócz fotowoltaiki, wsparcie finansowe będzie przewidziane również dla pomp ciepła oraz kolektorów słonecznych. Mój Prąd 5.0 oferuje wsparcie finansowe dla osób, które wytwarzają energię elektryczną na własne potrzeby. Pierwsze dwie edycje programu oferowały wsparcie na poziomie 5000 złotych, trzecia edycja na poziomie 3000 złotych. Mimo, że były to stosunkowo niewielkie kwoty w porównaniu do IV edycji, program odniósł spektakularny sukces. Budżet trzeciej edycji programu skończył się już po trzech miesiącach od uruchomienia dofinansowania. W kwietniu mają zostać ogłoszone warunki tegorocznej wersji programu Mój prąd. Jeśli myślicie o montażu instalacji pv lub pompy ciepła skontaktujcie się z nami już teraz, aby później nie żałować w razie zbyt szybkiego wyczerpania środków z programu."
        knowMore="programu mój prąd"
        />
      );
}
 
export default MojPrad50;