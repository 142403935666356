import React, {useState, useEffect} from 'react';

import './App.css';
import CookieConsent from "react-cookie-consent";

import Main from './main/Main';
import {Route, Routes,BrowserRouter} from 'react-router-dom';
import Privacy from './component/Privacy';
import Footer from './component/Footer';
import Contact from './Contact/Contact';
import Portfolio from './component/Porfolio/Portfolio';
import Finance from './component/finance/Finance';
import About from './component/aboutUs/About';
import Services from './component/Services/Services';
import Knowledge from './component/knowledge/Knowledge';
import Fotowoltaika from './component/knowledge/Articles/Fotowoltaika';
import PompyCiepla from './component/knowledge/Articles/Pompy-Ciepla';
import MagazynyEnergii from './component/knowledge/Articles/MagazynyEnergii';
import CzyNetBillingSieOplaca from './component/knowledge/Articles/CzyNetBillingSieOplaca';
import JakDzialaNetBilling from './component/knowledge/Articles/JakDzialaNetBilling';
import MojPrad from './component/knowledge/Articles/Mojprad';
import PompaDlaMnie from './component/knowledge/Articles/PompaDlaMnie';
import PompaFoto from './component/knowledge/Articles/PompaFoto';
import PompaJakDziala from './component/knowledge/Articles/PompaJakDziala';
import PvPodzespoly from './component/knowledge/Articles/PvPodzespoly';
import Ladowarki from './component/knowledge/Articles/Ladowarki';
import BezMontazu from './component/knowledge/Articles/BezMontazu';
import FotowoltaikaDlaFirm from './component/knowledge/Articles/FotowoltaikaDlaFirm';
import MojPrad50 from './component/knowledge/Articles/MojPrad50';
import NotPaid from './notpaid';
function App() {
  const [isLoading, setLoading] = useState(true);
  function someRequest() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise(resolve => setTimeout(() => resolve(), 2500));
  } 
  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });
  

  if (isLoading) {
    return null;
  }
  return (
    <BrowserRouter>
    <div className="App">
      <Routes>

<Route path='/' element={<Main title="Fotowoltaika i Pompy Ciepła - Comeko.pl" />} />
<Route path='/kontakt' element={<Contact title="Kontakt - Skontaktuj się z nami - Fotowoltaika i Pompy Ciepła - Comeko.pl" />} />
<Route path='/portfolio' element={<Portfolio title="Realizacje - Nasze Projekty w Fotowoltaice i Pompach Ciepła - Comeko.pl" />} />
<Route path='/finansowanie' element={<Finance title="Finansowanie - Fotowoltaika i Pompy Ciepła - Comeko.pl" />} />
<Route path='/o-nas' element={<About title="O nas - Comeko.pl - Specjaliści w Fotowoltaice i Pompach Ciepła" />} />
<Route path='/uslugi' element={<Services title="Usługi - Fotowoltaika i Pompy Ciepła - Comeko.pl" />} />
<Route path='/wiedza' element={<Knowledge title="Wiedza - Fotowoltaika i Pompy Ciepła - Poradnik i Informacje - Comeko.pl" />} />
<Route path='/wiedza/pompy-ciepla' element={<PompyCiepla title="Pompy ciepła - Artykuły i Informacje - Comeko.pl" />} />
<Route path='/wiedza/fotowoltaika' element={<Fotowoltaika title="Fotowoltaika - Artykuły i Poradnik - Comeko.pl" />} />
<Route path='/wiedza/magazyny-energii' element={<MagazynyEnergii title="Magazyny energii - Artykuły i Informacje - Comeko.pl" />} />
<Route path='/wiedza/czy-net-billing-sie-oplaca' element={<CzyNetBillingSieOplaca title="Net Billing - Czy się opłaca? - Artykuł - Comeko.pl" />} />
<Route path='/wiedza/jak-dziala-net-billing' element={<JakDzialaNetBilling title="" />} />
<Route path='/wiedza/moj-prad' element={<MojPrad title="" />} />
<Route path='/wiedza/pompy-ciepla-czy-jest-dla-mnie' element={<PompaDlaMnie title="" />} />
<Route path='/wiedza/pompy-ciepla-i-fotowoltaika-duet-idealny' element={<PompaFoto title="Fotowoltaika i Pompy Ciepła - Idealny Duet - Artykuł - Comeko.pl" />} />
<Route path='/wiedza/popmpy-ciepla-jak-dziala-pompa-powietrzna' element={<PompaJakDziala title="" />} />
<Route path='/wiedza/pv-jakie-podzespoly-wybrac' element={<PvPodzespoly title="Instalacje Fotowoltaiczne - Jakie podzespoły wybrać? - Artykuł - Comeko.pl" />} />
<Route path='/wiedza/ladowarki-samochodowe' element={<Ladowarki title="" />} />
<Route path='/wiedza/zestawy-bez-montazu' element={<BezMontazu title="" />} />
<Route path='/wiedza/fotowoltaika-dla-firm' element={<FotowoltaikaDlaFirm title="" />} />
<Route path='/wiedza/moj-prad-5-0' element={<MojPrad50 title="Mój prąd 5.0 - Najnowsza wersja mojego prądu dostępna ju od kwietnia 2023 roku! " />} />

<Route path='/polityka-prywatnosci' element={<Privacy title="Polityka prywatności Comeko" />} />




      </Routes>
      <CookieConsent
      location="bottom"
      buttonText="Rozumiem!"
      cookieName="komunikatCookie"
      style={{ background: "#90c652" }}
      buttonStyle={{ color: "#fff", backgroundColor:"#00b0f0", fontSize: "15px", borderRadius:"5px" }}
      expires={150}
      
      >Ta strona używa plików Cookie. Korzystając z niej wyrażasz zgodę na ich używanie.</CookieConsent>

<Footer/>
<NotPaid/>
    </div>
</BrowserRouter>
  );
}

export default App;
