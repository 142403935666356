import React from 'react';
import './notpaid.css';

const NotPaid = () => {

   
    return(

        <div className="niema"></div>
    )
    }
     
    export default NotPaid;